/* eslint-disable react/prop-types */

import React, { Suspense, lazy, useEffect } from "react"
import PropTypes from "prop-types"
import { gTagScriptConsent } from "../components/Scripts/Gtm.Head"
let hasOTScript
const BreadcrumbWrapper = lazy(() =>
  import("../components/BreadcrumbWrapper/BreadcrumbWrapper")
)
const ContentfulComponents = lazy(() =>
  import("../helpers/ContentfulComponents")
)
const Link = lazy(() => import("../components/UI/Link/Link"))
const Layout = lazy(() => import("../components/Layout/Layout"))

const Page = ({ pageContext }) => {
  const {
    globalComponents,
    componentProps,
    seoData,
    slug,
    pageType,
    dataLayer,
    topDisclaimer,
    isChevronIcon,
    scriptId,
    hasOneTrustScipt,
    hasMoreLessButton,
    dataLayerSiteLanguage,
  } = pageContext

  if (dataLayer?.GTM) {
    dataLayer.GTM.SiteLanguage = dataLayerSiteLanguage
      ? dataLayerSiteLanguage
      : "en"
  }

  useEffect(() => {
    if (scriptId && hasOneTrustScipt && typeof window !== "undefined") {
      const oneTrustId = scriptId?.replace("otnotice-", "")
      const isProdEnv = process.env.GATSBY_ACTIVE_ENV === "production"
      window.hasOTScript = hasOneTrustScipt
      const endpoint = `https://privacyportalde-cdn.onetrust.com/f69c0bf1-10ab-4d33-8b59-e235ddd37a5f/privacy-notices/${oneTrustId}.json`
      setTimeout(() => {
        if (typeof window !== "undefined" && window.OneTrust?.NoticeApi) {
          OneTrust.NoticeApi.Initialized.then(async function () {
            await OneTrust.NoticeApi.LoadNotices([endpoint], isProdEnv)
            const sectionId = window.location.hash.split("#")["1"]
            if (sectionId) {
              const sectionElement = document.getElementById(sectionId)
              if (sectionElement) {
                sectionElement.scrollIntoView()
              }
            }
          })
        }
      }, 500)
    }
  }, [])

  // GTM custom dataLayer Object based on environment
  if (typeof window !== "undefined") {
    window.$ = window.jquery = window.jQuery = require("jquery")
    //ESG NEWS Widget Script invoke
    if (pageType === "ESG News") {
      const script = document.createElement("script")
      script.src =
        "https://www.3blmedia.com/sites/www.3blmedia.com/modules/custom_modules/threebl_widgets/inc/widget_function.js"
      script.id = "widgets"
      document.body.appendChild(script)
      script.onload = () => {
        window.init_card_widget(
          "M2ExNWM3ZDBiYmU2MDMwMGEzOWY3NmY4YTViYTY4OTY=",
          "ZGV0YWlscw=="
        )
      }
    }
    const dataLayerObj = {
      ...dataLayer,
      page: {
        url: window.location.href,
        title: seoData?.title || seoData?.metaTitle,
      },
      content: {
        type: pageType || "others",
        category: "",
      },
    }
    window.gtmDataLayer = dataLayerObj
  }

  return (
    <Suspense fallback="">
      <Layout
        seoData={seoData}
        globalComponents={globalComponents}
        topDisclaimer={topDisclaimer}
        hasMoreLessButton={hasMoreLessButton}
        preloadComp={componentProps ? componentProps[0] : {}}
        slug={slug}
        pageType={pageType}
      >
        {componentProps?.includes("BreadcrumbWrapper") && <BreadcrumbWrapper />}
        {hasOneTrustScipt && componentProps?.length > 0 ? (
          <>
            <BreadcrumbWrapper {...componentProps[0]["BreadcrumbWrapper"]} />
            {/* Container in which the privacy notice will be rendered  */}
            <div id={scriptId} className={`otnotice`}></div>
          </>
        ) : (
          <ContentfulComponents
            componentsProps={componentProps}
            pageType={pageType}
          />
        )}
        {/* adding chevron Icon */}
        {isChevronIcon && (
          <Link
            className="toTop"
            scrollToElementId={"global-header"}
            label={"TOP"}
          />
        )}
      </Layout>
    </Suspense>
  )
}

export const Head = () => {
  var pathname =
    typeof window !== "undefined" ? window?.location?.pathname : "/"
  const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}
  if (typeof window !== "undefined") {
    if (window?.hasOTScript) {
      return (
        <>
          <script
            src="https://privacyportalde-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js"
            type="text/javascript"
            id="otprivacy-notice-script"
            defer
          ></script>
        </>
      )
    }
  }
  return (
    <>
      {process.env.GATSBY_ACTIVE_ENV !== "production" &&
        (pathname === "/Snacking-Made-Right/Reporting-and-Disclosure" ||
          pathname === "/Snacking-Made-Right/Reporting-and-Disclosure/" ||
          pathname === "/snacking-made-right/reporting-and-disclosure" ||
          pathname === "/snacking-made-right/reporting-and-disclosure/") && (
          <script
            id="chatbotScript"
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `var AvaamoChatBot=function(t){function o(t,o){var n=document.createElement("script");n.setAttribute("src",t),n.setAttribute("id","avm-web-channel"),n.onload=o,document.body.appendChild(n)}return this.options=t||{},this.load=function(t){o(this.options.url,function(){window.Avaamo.addFrame(),t&&"function"==typeof(t)&&t(window.Avaamo)})},this}; var chatBox = new AvaamoChatBot({url: "https://c0.avaamo.com/web_channels/e1e4b75c-95ce-4cf7-b88e-0a00d46faaad"}); chatBox.load();`,
            }}
          />
        )}
      <script
        id="first-unique-id"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
        }}
      />
      <script
        async
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
      />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
        integrity="sha512-1ycn6IcaQQ40/MKBW2W4Rhis/DbILU74C1vSrLJxCq57o941Ym01SwNsOMqvEBFlcgUa6xLiPY/NS5R+E6ztJQ=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      />
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.4/jquery.min.js" />
      <script src="https://cdn.jsdelivr.net/npm/js-cookie@3.0.1/dist/js.cookie.min.js"></script>
    </>
  )
}

Page.propTypes = {
  pageContext: PropTypes.shape({
    globalComponents: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    componentProps: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    pageType: PropTypes.string,
    dataLayer: PropTypes.object,
    dataLayerSiteLanguage: PropTypes.string,
    topDisclaimer: PropTypes.object,
    isChevronIcon: PropTypes.bool,
    hasMoreLessButton: PropTypes.bool,
    seoData: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      ogTitle: PropTypes.string,
      ogDescription: PropTypes.string,
      ogType: PropTypes.string,
      twitterTitle: PropTypes.string,
      twitterDescription: PropTypes.string,
      canonicalLink: PropTypes.string,
      metaTitle: PropTypes.string,
      featuredImage: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
}

export default Page
